import React from "react"
import style from "./button.module.css"
import {navigate} from "gatsby";

export default ({type, attr, children}) => (
  <button className={style.button} type={type} {...attr}>{children}</button>
)

export const ButtonThin = ({attr, children}) => (
  <button className={style.buttonThin} type="button" {...attr}>{children}</button>
)

export const ButtonTop = () => (
  <button className={style.button} type="button" onClick={() => navigate("/")}>TOPへ戻る</button>
)

export const ButtonAccountTop = () => (
  <button className={style.button} type="button" onClick={() => navigate("/account/")}>アカウント管理TOPへ戻る</button>
)

export const ButtonMenu = ({click, children}) => (
  <button className={style.buttonMenu} type="button" onClick={click}>{children}</button>
)

export const ButtonLink = ({href, children}) => (
  <a className={style.buttonMenu} href={href}>{children}</a>
)

export const ButtonClose = ({onClick}) => (
  <button className={style.buttonClose} type="button" aria-label="close button" onClick={onClick}><span/><span/></button>
)