import React from "react"
import Layout from "../components/Layout"
import PageTitle from "../components/PageTitle";
import classNames from "classnames";
import Button from "../components/Button";
import {navigate} from "gatsby";
import PageNavigation from "../components/PageNavigation";
import SEO from "../components/SEO";

export default () => (
  <Layout>
    <SEO title="404 NOT FOUND"/>
    <section className="inner">
      <PageTitle>404 NOT FOUND</PageTitle>
      <div className={classNames("container", "bg-white", "inner")}>
        <p style={{
          padding: "30px 0",
        }}>お探しのページが見つかりませんでした。</p>
      </div>
      <PageNavigation>
        <Button type={"button"} attr={{
          onClick: () => navigate("/")
        }}>TOPへ戻る</Button>
      </PageNavigation>
    </section>
  </Layout>
)